"use strict"


class Dropdown {
    // menusにトリガーとなるNodeListを渡す
    // targetに表示させる要素名を渡す
    constructor(menus, target) {
        this.menus = menus;
        this.target = target;
        this.dropdown();
    }

    dropdown() {
        const target = this.target
        this.menus.forEach(menu => {
            menu.addEventListener("mouseenter", function(){
                const ddmenu = this.querySelector(target);
                if (ddmenu){
                    menu.classList.add('show');
                }
            });
            menu.addEventListener("mouseleave", function(){
                menu.classList.remove('show')
            })
        })
    }
}

document.addEventListener("DOMContentLoaded", ()=> {
    const elem = document.querySelectorAll(".header__menu > li")
    if (elem) {
        new Dropdown(elem,".ddmenu")
    }
})


function globalNaviOpen(){
    const button = document.getElementById('js-humberger')
    const body = document.getElementsByClassName('globalNavi')[0]
    const html = document.querySelector('html')
    if(button){
        const alinks = body.querySelectorAll('a[href]')
        button.addEventListener('click', () => {
            if (body.classList.contains('is-drawerActive') || html.classList.contains('is-drawerActive')){
                body.classList.remove('is-drawerActive')
                html.classList.remove('is-drawerActive')
            } else {
                body.classList.add('is-drawerActive')
                html.classList.add('is-drawerActive')
            }
    
            if (button.getAttribute('aria-expanded') == 'false'){
                button.setAttribute('aria-expanded', true)
            } else {
                button.setAttribute('aria-expanded', false)
            }
        })
        alinks.forEach(alink => {
            alink.addEventListener('click', ()=> {
                if (body.classList.contains('is-drawerActive') || html.classList.contains('is-drawerActive')){
                    body.classList.remove('is-drawerActive')
                    html.classList.remove('is-drawerActive')
                }
                if (button.getAttribute('aria-expanded') == 'true'){
                    button.setAttribute('aria-expanded', false)
                } 
            })
        })
        
    }
    
}

function acordion() {
    const parents = document.querySelectorAll('.acParent')
    if (parents){
        parents.forEach( parent => {
            parent.addEventListener('click', function(){
                if(this.classList.contains('active')){
                    this.classList.remove('active')
                } else {
                    this.classList.add('active')
                }
            })
        })
    }
    
}


function startObserve () {
  // 見えている量が閾値を上回るか下回ったときに呼ばれる
  const callback = (entries, observer) => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        // tagetが見えている時
        header.classList.add('hide')
        humberger.classList.add('hide')
      } else if(entry.intersectionRatio === 0) {
        // tagetが全て見えなくなった時
        header.classList.remove('hide')
        humberger.classList.remove('hide')
      }
    })
  }

  const option = {
    // 20%と100％の閾値
    threshold: [0.2, 1]
  }

  const observer = new IntersectionObserver(
    callback
  )
  const header = document.querySelector('.headerMember')
  const humberger = document.querySelector('.threestripes__wrap')
  const target = document.querySelector('.submenu')
  if (target) {
      observer.observe(target)
  }
}





document.addEventListener("DOMContentLoaded", ()=>{
    globalNaviOpen();
    acordion();
    // startObserve();
})